:root {
  --lightblue: rgb(92 124 238 / 32%);
  --darkblue: #303f9f;
  --lightPrimary: color-mix(in srgb, var(--darkblue) 50%, white);
}

#root {
  width: 100%;
  height: 100%;
  /* zoom: 90%; */
  display: flex;
  flex-direction: column;
}

.max-width {
  width: 100%;
}
.max-height {
  height: 100%;
}

.MuiDialog-paperFullScreen {
  /* dialog height issue for constraints and layouts ......... */
  max-height: 100% !important;
}

.horizonal-divider {
  border-bottom: 1px solid black;
}

.small-top-padding {
  padding-top: 10px;
}

.ui.button.button-color {
  background-color: lightseagreen;
  color: white;
}

.flex-display {
  display: flex;
}

.colum-direction {
  flex-direction: column;
}

.default-padding {
  padding: 10px;
}

.grey-menu {
  background-color: lightgrey;
}

.default-header {
  display: flex;
  justify-content: space-between;
}

.fit-content {
  height: fit-content;
}

.invisible {
  visibility: hidden;
}

/* html body {
    height: auto;
} */

.container-table {
  /* display: block; */
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto; /* or auto */
  overflow-x: hidden;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.table tfoot tr:first-child > th {
  position: sticky !important;
  bottom: 0;
  z-index: 2;
}

.xyz {
  margin-bottom: 20px;
  width: 500px;
}
/* .abc{
    padding-right: 40px;
    width: 200px;
} */
/* .alpha{
  padding-left: 30px;
  margin-bottom: 40px;
  width: 100px;
} */
.beta {
  padding-right: 40px;
  width: 200px;
  display: flex;
  margin-bottom: 20px;
}

.notification-list,
.n-user-list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.notification-list > li {
  /* border-bottom: 1px solid #eee; */
  border-bottom: 1px solid #9fb3f24d;
  margin-bottom: 5px;
  padding: 5px 0;
}

/* .notification-list ul li a {
    color: black;
   background-color: 'white';
}

.notification-list ul li a:hover {
    background-color: 'pink';
} */

.notification-list .cat-icon {
  width: 20px;
}
.notification-list .avatar {
  width: 30px;
}

.rounded {
  border-radius: 0.25rem;
}
.n-user-list {
  margin-bottom: 5px;
}
.n-user-list:after {
  clear: both;
  content: "";
  display: table;
}
.n-user-list li {
  float: left;
}
.n-user-list li + li {
  margin-left: 3px;
}
.n-user-list li a,
.n-user-list li a:hober {
  text-decoration: none;
}

/*---------------------- Grid styling---------------------- */

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: var(--darkblue) !important;
  color: #fff !important;
  border-color: transparent;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: var(--darkblue) !important;
  border-color: transparent;
  color: #fff;
}

.e-popup.e-popup-open.e-dialog {
  display: inline-flex;
  /* height: 400px !important; */
  height: 380px !important;
}

.e-columnheader {
  color: black !important;
}

.e-grid .e-headercelldiv {
  /* font-weight: bolder; */
  font-size: 13px;
}

.picklist-icon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input:first-child {
  padding-left: 40px !important;
}
.e-split-bar:hover {
  color: var(--darkblue) !important;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover {
  /* background: #fff; */
  background: var(--darkblue) !important;
}
.tabColor {
  color: var(--darkblue) !important;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  background: #fff;
  color: var(--darkblue) !important;
}

.e-grid .e-altrow {
  /* background-color: #fafafa; */
}

.e-grid td.e-selectionbackground {
  background-color: var(--lightblue) !important;
}

.e-grid td.e-active {
  background-color: var(--lightblue) !important;
}

.e-grid .e-groupheadercell {
  background-color: var(--darkblue) !important;
}

.e-grid .e-groupdroparea {
  background-color: var(--lightPrimary) !important;
  padding-top: -1;
}

.e-detailcell .e-detailrow td.e-rowcell.e-active {
  background-color: var(--lightblue) !important;
}
/* .e-grid.e-gridhover { 
    background-color: rgb(92 124 238 / 32%)!important; 
  }  */

.e-grid.e-gridhover
  tr.e-row:hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell) {
  background-color: var(--lightblue) !important;
}

.forInputTypeLables {
  text-align: "left";
  font-size: 14px;
  font-weight: 600;
}

.forInputTypeLables-new {
  font-weight: 400;
  line-height: 1.57;
  font-size: 14px;
  padding: 0px;
  color: #667085;
  text-overflow: ellipsis;
  font-family: "Inter", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  margin: 0px;
}

/* For disabling checkboxes at Type attributes Admin side */
.disablecheckbox {
  pointer-events: none;
  opacity: 0.5;
}

.e-excel-ascending,
.e-excel-descending,
.e-separator.e-excel-separator {
  display: none;
}

.e-grid .e-frozenheader > .e-table,
.e-grid .e-frozencontent > .e-table,
.e-grid .e-frozencontent .e-virtualtable > .e-table,
.e-grid .e-frozenheader .e-virtualtable > .e-table {
  border-right-width: 0;
}

.e-grid .e-movableheader table.e-table,
.e-grid .e-movablecontent table.e-table {
  width: 100% !important;
}

.e-grid .e-ccdlg .e-dlg-content {
  margin: 0px 0 0 !important;
}
.e-grid .e-ccdlg .e-cc.e-input,
.e-grid .e-ccdlg .e-cc.e-input:focus {
  padding-top: 10% !important;
}

#rte #toolsRTE_rte-edit-view ol {
  padding-inline-start: 30px !important;
}

#rte #toolsRTE_rte-edit-view ul {
  padding-inline-start: 30px !important;
}

/*For Query Builder*/
#querybuilder .e-template-value {
  width: 30% !important;
}

#querybuilder .e-custom-value {
  display: inline-flex;
  width: 100% !important;
}

#querybuilder .e-radio-wrapper {
  margin: 5px 0px 0px 15px !important;
}

.e-rule-operator .e-control-wrapper {
  margin-left: 10px;
}

.e-grid .e-filter-popup.e-dialog .e-dlg-content {
  overflow-y: hidden !important;
}

.e-grid .e-excelfilter {
  max-height: 334px !important;
}

.e-grid .e-filter-popup.e-dialog .e-dlg-content .e-checkboxlist {
  min-height: 50px;
  height: 160px;
}

.e-rule-value.e-template-value {
  width: auto !important;
}
.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
  box-shadow: 0 0 0 0px #9e9e9e inset !important;
}

.e-grid .e-headercell {
  background-color: #f8f9f9 !important; /* Override the header background color */
  color: #9da1a1 !important;
}
.e-grid .e-gridpager {
  font-family: cursive;
}
.e-grid .e-gridpager .e-currentitem {
  background-color: var(--darkblue) !important;
}

.e-grid .e-sortnumber {
  background-color: var(--lightPrimary) !important;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  background-color: #fff;
  border-color: #9da1a1 !important;
  border-radius: 5px !important;
}

.e-grid {
  border-radius: 10px !important;
  -webkit-box-shadow: 2px 0px 12px -9px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 2px 0px 12px -9px rgba(0, 0, 0, 0.66);
  box-shadow: 2px 0px 12px -9px rgba(0, 0, 0, 0.66);
}

.e-grid .e-gridheader {
  background-color: #f8f9f9 !important;
}

.e-groupdroparea {
  border-radius: 10px 10px 0 0;
}

.e-grid:not(:has(.e-groupdroparea)) > .e-gridheader {
  border-radius: 10px 10px 0 0;
}

.e-gridpager {
  border-radius: 0 0 10px 10px;
}

.e-grid:not(:has(.e-gridpager)) > .e-gridfooter {
  border-radius: 0 0 10px 10px;
}

.e-grid:not(:has(.e-gridpager)):not(:has(.e-gridfooter)) > .e-gridcontent {
  border-radius: 0 0 10px 10px;
}

.e-grid .e-rowcell {
  line-height: 2.5 !important;
  font-size: 14px;
}

.refHyperLink:hover {
  text-decoration: underline;
}

.refHyperLink {
    color: #009fda;
}

.suspectHyperLink:hover {
  text-decoration: underline;
}

.suspectHyperLink {
    color: "red";
}

/*To hide scrollbar of tree greed if data is not overflowing*/
.e-treegrid .e-grid .e-content {
  overflow-y: auto !important;
}

