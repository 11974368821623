.inputLabels-toobarReln {
  font-weight: 400; 
  line-height: 1.57;
  font-size: 14px;
  padding: 0px;
  color: #667085;
  text-overflow: ellipsis;
  font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  margin: 0px
  }

  .mandatoryStar {
   color: #D32F2F; 
  }

  .relnInfo {
    margin-top: -4px;
  }

  .NameAndTooltip {
    display: flex;
    justify-content: space-between;

  }

  .chip-relationship-count {
    margin-left: 5px;
  }