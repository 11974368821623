.actionSkeleton {
    width: 100px;
}

.actionSkeletonDiv {
    margin: 10px;
}

.no-action-msg {
    margin: 20px;
    background-color: #c7c7c74d;
}

.action-items {
    margin: 10px;
}

.linear-loader {
    margin-bottom: 10px;
}
