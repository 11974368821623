.buttonstyle {
  padding-right: "20";
  padding-left: "20";
}
.dialogueContent {
  margin-bottom: "10px";
  overflow: "hidden";
}
.dialogueAction {
  margin-top: 10;
}
