.previewHeading {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
    font-weight: bold;
    font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    line-height: 1.57;
}

.previewHeadingWithoutBold {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
}