#relationBox {
 padding: 12px 0px;
}

.gridHeightandwidth-externalTypeView {
    height: calc(100vh - 205px)
}

/* This is to disable row when user is creating from reltionship and parent is attaching by default then user can not perform any oeration on that row*/
.disabled-row {
    pointer-events: none; 
    background-color: #f5f5f5;
    color: #a0a0a0;
  }
  