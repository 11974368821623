
@import url('https://fonts.googleapis.com/css?family=Inter');

.e-richtexteditor.e-rte-tb-expand p{
    /* display: none !important */
    /* This is commented as it is causing the issue with rich text content on blur */
  }

  .root {
    display: "flex";
    align-items: "center";
  }

  .loaderStyle {
    width: 300;
  }

  .inputLabels {
    font-weight: 400; 
    line-height: 1.57;
    font-size: 14px;
    padding: 0px;
    color: #667085;
    text-overflow: ellipsis;
    font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    margin: 0px
  }

  .inputField {
    margin-bottom: 15px;
    margin-top: 3px;
  }

  .parahSpace {
   margin: 0px;
   font-weight: 500;
   font-size: 14px;
   text-overflow: ellipsis; 
   line-height: 1.57;
   color: rgb(0, 0, 0);
   padding: 12px 0px;
   font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  }

  .parahSpace-tooltip {
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    line-height: 1.57;
    color: rgb(0, 0, 0);
    padding: 7px 0px;
    font-family: "Inter", "-apple-system", "BlinkMacSystemFont" ,"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
   }

   .anchorText:hover {
    text-decoration: underline;
}

.error {
  color: red;
}

.not-error {
  color: green
}

.chip-count {
  margin-left: 5px
}

.attribute-name {
  display: inline-block;
}
 