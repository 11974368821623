@media screen and (min-height: 3840px)and (max-height: 3860px)  {
  .gridHeightandwidth {
    height: 3000px;
  }
}

@media screen and (min-height: 3020px)and (max-height: 3840px)  {
  .gridHeightandwidth {
    height: 2800px;
  }
}

@media screen and (min-height: 3000px)and (max-height: 3020px)  {
  .gridHeightandwidth {
    height: 2760px;
  }
}
@media screen and (min-height: 2980px)and (max-height: 3000px)  {
  .gridHeightandwidth {
    height: 2740px;
  }
}
@media screen and (min-height: 2960px)and (max-height: 2980px)  {
  .gridHeightandwidth {
    height: 2720px;
  }
}
@media screen and (min-height: 2940px)and (max-height: 2960px)  {
  .gridHeightandwidth {
    height: 2700px;
  }
}
@media screen and (min-height: 2920px)and (max-height: 2940px)  {
  .gridHeightandwidth {
    height: 2680px;
  }
}

@media screen and (min-height: 2900px)and (max-height: 2920px)  {
  .gridHeightandwidth {
    height: 2660px;
  }
}


@media screen and (min-height: 2880px)and (max-height: 2900px)  {
  .gridHeightandwidth {
    height: 2640px;
  }
}


@media screen and (min-height: 2860px)and (max-height: 2880px)  {
  .gridHeightandwidth {
    height: 2620px;
  }
}

@media screen and (min-height: 2840px)and (max-height: 2860px)  {
  .gridHeightandwidth {
    height: 2600px;
  }
}

@media screen and (min-height: 2820px)and (max-height: 2840px)  {
  .gridHeightandwidth {
    height: 2580px;
  }
}

@media screen and (min-height: 2800px)and (max-height: 2820px)  {
  .gridHeightandwidth {
    height: 2560px;
  }
}

@media screen and (min-height: 2780px)and (max-height: 2800px)  {
  .gridHeightandwidth {
    height: 2540px;
  }
}


@media screen and (min-height: 2760px)and (max-height: 2780px)  {
  .gridHeightandwidth {
    height: 2520px;
  }
}

@media screen and (min-height: 2740px)and (max-height: 2760px)  {
  .gridHeightandwidth {
    height: 2500px;
  }
}@media screen and (min-height: 2720px)and (max-height: 2740px)  {
  .gridHeightandwidth {
    height: 2480px;
  }
}@media screen and (min-height: 2700px)and (max-height: 2720px)  {
  .gridHeightandwidth {
    height: 2460px;
  }
}@media screen and (min-height: 2680px)and (max-height: 2700px)  {
  .gridHeightandwidth {
    height: 2440px;
  }
}@media screen and (min-height: 2660px)and (max-height: 2680px)  {
  .gridHeightandwidth {
    height: 2420px;
  }
}@media screen and (min-height: 2640px)and (max-height: 2660px)  {
  .gridHeightandwidth {
    height: 2400px;
  }
}@media screen and (min-height: 2620px)and (max-height: 2640px)  {
  .gridHeightandwidth {
    height: 2380px;
  }
}@media screen and (min-height: 2600px)and (max-height: 2620px)  {
  .gridHeightandwidth {
    height: 2360px;
  }
}@media screen and (min-height: 2580px)and (max-height: 2600px)  {
  .gridHeightandwidth {
    height: 2340px;
  }
}@media screen and (min-height: 2560px)and (max-height: 2580px)  {
  .gridHeightandwidth {
    height: 2320px;
  }
}


@media screen and (min-height: 2540px)and (max-height: 2560px)  {
  .gridHeightandwidth {
    height: 2300px;
  }
}

@media screen and (min-height: 2520px)and (max-height: 2540px)  {
  .gridHeightandwidth {
    height: 2280px;
  }
}



@media screen and (min-height: 2500px)and (max-height: 2520px)  {
  .gridHeightandwidth {
    height: 2260px;
  }
}

@media screen and (min-height: 2480px)and (max-height: 2500px)  {
  .gridHeightandwidth {
    height: 2240px;
  }
}

@media screen and (min-height: 2460px)and (max-height: 2480px)  {
  .gridHeightandwidth {
    height: 2220px;
  }
}

@media screen and (min-height: 2440px)and (max-height: 2460px)  {
  .gridHeightandwidth {
    height: 2200px;
  }
}

@media screen and (min-height: 2420px)and (max-height: 2440px)  {
  .gridHeightandwidth {
    height: 2180px;
  }
}

@media screen and (min-height: 2400px)and (max-height: 2420px)  {
  .gridHeightandwidth {
    height: 2160px;
  }
}

@media screen and (min-height: 2380px)and (max-height: 2400px)  {
  .gridHeightandwidth {
    height: 2140px;
  }
}

@media screen and (min-height: 2360px)and (max-height: 2380px)  {
  .gridHeightandwidth {
    height: 2120px;
  }
}

@media screen and (min-height: 2340px)and (max-height: 2360px)  {
  .gridHeightandwidth {
    height: 2100px;
  }
}

@media screen and (min-height: 2320px)and (max-height: 2340px)  {
  .gridHeightandwidth {
    height: 2080px;
  }
}

@media screen and (min-height: 2300px)and (max-height: 2320px)  {
  .gridHeightandwidth {
    height: 2060px;
  }
}

@media screen and (min-height: 2280px)and (max-height: 2300px)  {
  .gridHeightandwidth {
    height: 2040px;
  }
}

@media screen and (min-height: 2260px)and (max-height: 2280px)  {
  .gridHeightandwidth {
    height: 2020px;
  }
}

@media screen and (min-height: 2240px)and (max-height: 2260px)  {
  .gridHeightandwidth {
    height: 2000px;
  }
}

@media screen and (min-height: 2220px)and (max-height: 2240px)  {
  .gridHeightandwidth {
    height: 1980px;
  }
}
@media screen and (min-height: 2200px)and (max-height: 2220px)  {
  .gridHeightandwidth {
    height: 1960px;
  }
}
@media screen and (min-height: 2180px)and (max-height: 2200px)  {
  .gridHeightandwidth {
    height: 1940px;
  }
}
@media screen and (min-height: 2160px)and (max-height: 2180px)  {
  .gridHeightandwidth {
    height: 1920px;
  }
}
@media screen and (min-height: 2140px)and (max-height: 2160px)  {
  .gridHeightandwidth {
    height: 1900px;
  }
}
@media screen and (min-height: 2120px)and (max-height: 2140px)  {
  .gridHeightandwidth {
    height: 1880px;
  }
}
@media screen and (min-height: 2100px)and (max-height: 2120px)  {
  .gridHeightandwidth {
    height: 1860px;
  }
}
@media screen and (min-height: 2080px)and (max-height: 2100px)  {
  .gridHeightandwidth {
    height: 1840px;
  }
}
@media screen and (min-height: 2060px)and (max-height: 2080px)  {
  .gridHeightandwidth {
    height: 1820px;
  }
}@media screen and (min-height: 2040px)and (max-height: 2060px)  {
  .gridHeightandwidth {
    height: 1800px;
  }
}

@media screen and (min-height: 2020px)and (max-height: 2040px)  {
  .gridHeightandwidth {
    height: 1780px;
  }
}@media screen and (min-height: 2000px)and (max-height: 2020px)  {
  .gridHeightandwidth {
    height: 1760px;
  }
}@media screen and (min-height: 1980px)and (max-height: 2000px)  {
  .gridHeightandwidth {
    height: 1740px;
  }
}@media screen and (min-height: 1960px)and (max-height: 1980px)  {
  .gridHeightandwidth {
    height: 1720px;
  }
}@media screen and (min-height: 1940px)and (max-height: 1960px)  {
  .gridHeightandwidth {
    height: 1700px;
  }
}@media screen and (min-height: 1920px)and (max-height: 1940px)  {
  .gridHeightandwidth {
    height: 1680px;
  }
}@media screen and (min-height: 1900px)and (max-height: 1920px)  {
  .gridHeightandwidth {
    height: 1660px;
  }
}@media screen and (min-height: 1880px)and (max-height: 1900px)  {
  .gridHeightandwidth {
    height: 1640px;
  }
}@media screen and (min-height: 1860px)and (max-height: 1880px)  {
  .gridHeightandwidth {
    height: 1620px;
  }
}@media screen and (min-height: 1840px)and (max-height: 1860px)  {
  .gridHeightandwidth {
    height: 1600px;
  }
}


@media screen and (min-height: 1820px)and (max-height: 1840px)  {
  .gridHeightandwidth {
    height: 1580px;
  }
}

@media screen and (min-height: 1800px)and (max-height: 1820px)  {
  .gridHeightandwidth {
    height: 1560px;
  }
}



@media screen and (min-height: 1780px)and (max-height: 1800px)  {
  .gridHeightandwidth {
    height: 1540px;
  }
}

@media screen and (min-height: 1760px)and (max-height: 1780px)  {
  .gridHeightandwidth {
    height: 1520px;
  }
}

@media screen and (min-height: 1740px)and (max-height: 1760px)  {
  .gridHeightandwidth {
    height: 1500px;
  }
}

@media screen and (min-height: 1720px)and (max-height: 1740px)  {
  .gridHeightandwidth {
    height: 1480px;
  }
}

@media screen and (min-height: 1700px)and (max-height: 1720px)  {
  .gridHeightandwidth {
    height: 1460px;
  }
}

@media screen and (min-height: 1680px)and (max-height: 1700px)  {
  .gridHeightandwidth {
    height: 1440px;
  }
}

@media screen and (min-height: 1660px)and (max-height: 1680px)  {
  .gridHeightandwidth {
    height: 1420px;
  }
}

@media screen and (min-height: 1640px)and (max-height: 1660px)  {
  .gridHeightandwidth {
    height: 1400px;
  }
}

@media screen and (min-height: 1620px)and (max-height: 1640px)  {
  .gridHeightandwidth {
    height: 1380px;
  }
}

@media screen and (min-height: 1600px)and (max-height: 1620px)  {
  .gridHeightandwidth {
    height: 1360px;
  }
}

@media screen and (min-height: 1580px)and (max-height: 1600px)  {
  .gridHeightandwidth {
    height: 1340px;
  }
}

@media screen and (min-height: 1560px)and (max-height: 1580px)  {
  .gridHeightandwidth {
    height: 1320px;
  }
}

@media screen and (min-height: 1540px)and (max-height: 1560px)  {
  .gridHeightandwidth {
    height: 1300px;
  }
}

@media screen and (min-height: 1520px)and (max-height: 1540px)  {
  .gridHeightandwidth {
    height: 1280px;
  }
}

@media screen and (min-height: 1500px)and (max-height: 1520px)  {
  .gridHeightandwidth {
    height: 1260px;
  }
}
@media screen and (min-height: 1480px)and (max-height: 1500px)  {
  .gridHeightandwidth {
    height: 1240px;
  }
}
@media screen and (min-height: 1460px)and (max-height: 1480px)  {
  .gridHeightandwidth {
    height: 1220px;
  }
}
@media screen and (min-height: 1440px)and (max-height: 1460px)  {
  .gridHeightandwidth {
    height: 1200px;
  }
}
@media screen and (min-height: 1420px)and (max-height: 1420px)  {
  .gridHeightandwidth {
    height: 1180px;
  }
}
@media screen and (min-height: 1400px)and (max-height: 1420px)  {
  .gridHeightandwidth {
    height: 1160px;
  }
}
@media screen and (min-height: 1380px)and (max-height: 1400px)  {
  .gridHeightandwidth {
    height: 1140px;
  }
}
@media screen and (min-height: 1360px)and (max-height: 1380px)  {
  .gridHeightandwidth {
    height: 1100px;
  }
}
@media screen and (min-height: 1340px)and (max-height: 1360px)  {
  .gridHeightandwidth {
    height: 1090px;
  }
}@media screen and (min-height: 1320px)and (max-height: 1340px)  {
  .gridHeightandwidth {
    height: 1070px;
  }
}

@media screen and (min-height: 1300px)and (max-height: 1320px)  {
  .gridHeightandwidth {
    height: 1050px;
  }
}
@media screen and (min-height: 1280px)and (max-height: 1300px)  {
  .gridHeightandwidth {
    height: 1030px;
  }
}
@media screen and (min-height: 1260px)and (max-height: 1280px)  {
  .gridHeightandwidth {
    height: 1010px;
  }
}
@media screen and (min-height: 1240px)and (max-height: 1260px)  {
  .gridHeightandwidth {
    height: 990px;
  }
}
@media screen and (min-height: 1220px)and (max-height: 1240px)  {
  .gridHeightandwidth {
    height: 970px;
  }
}
@media screen and (min-height: 1200px)and (max-height: 1220px)  {
  .gridHeightandwidth {
    height: 950px;
  }
}

@media screen and (min-height: 1180px)and (max-height: 1200px)  {
  .gridHeightandwidth {
    height: 930px;
  }
}

@media screen and (min-height: 1160px)and (max-height: 1180px)  {
  .gridHeightandwidth {
    height: 910px;
  }
}

@media screen and (min-height: 1140px)and (max-height: 1160px)  {
  .gridHeightandwidth {
    height: 890px;
  }
}

@media screen and (min-height: 1120px)and (max-height: 1140px)  {
  .gridHeightandwidth {
    height: 870px;
  }
}


@media screen and (min-height: 1100px)and (max-height: 1120px)  {
  .gridHeightandwidth {
    height: 850px;
  }
}

@media screen and (min-height: 1080px)and (max-height: 1100px)  {
  .gridHeightandwidth {
    height: 830px;
    border-color: black;
    border-radius: 10px;
    border-width: 2px;
  }
}

@media screen and (min-height: 1060px)and (max-height: 1080px)  {
  .gridHeightandwidth {
    height: 810px;
    border-color: black;
    border-radius: 10px;
    border-width: 2px;
  }
}

@media screen and (min-height: 1040px)and (max-height: 1060px)  {
  .gridHeightandwidth {
    height: 790px;
  }
}

@media screen and (min-height: 1020px)and (max-height: 1040px)  {
  .gridHeightandwidth {
    height: 770px;
  }
}

@media screen and (min-height: 1000px)and (max-height: 1020px)  {
  .gridHeightandwidth {
    height: 750px;
  }
}

@media screen and (min-height: 980px)and (max-height: 1000px)  {
  .gridHeightandwidth {
    height: 730px;
  }
}

@media screen and (min-height: 960px)and (max-height: 980px)  {
  .gridHeightandwidth {
    height: 710px;
  }
}

 @media screen and (min-height: 940px)and (max-height: 960px)  {
  .gridHeightandwidth {
    height: 690px;
  }
}

 @media screen and (min-height: 920px)and (max-height: 940px)  {
  .gridHeightandwidth {
    height: 670px;
  }
}

 @media screen and (min-height: 900px)and (max-height: 920px)  {
  .gridHeightandwidth {
    height: 650px;
  }
}

 @media screen and (min-height: 880px)and (max-height: 900px)  {
  .gridHeightandwidth {
    height: 630px;
  }
}

 @media screen and (min-height: 860px)and (max-height: 880px)  {
  .gridHeightandwidth {
    height: 610px;
  }
}

 @media screen and (min-height: 840px)and (max-height: 860px)  {
  .gridHeightandwidth {
    height: 590px;
  }
}

 @media screen and (min-height: 820px)and (max-height: 840px)  {
  .gridHeightandwidth {
    height: 570px;
  }
}

@media screen and (min-height: 800px)and (max-height: 820px)  {
  .gridHeightandwidth {
    height: 550px;
  }
}

  @media screen and (min-height: 780px) and (max-height: 800px)  {
    .gridHeightandwidth {
      height: 530px;
    }
  }

  @media screen and (min-height: 760px) and (max-height: 780px)  {
    .gridHeightandwidth {
      height: 510px;
    }
  }

  @media screen and (min-height: 740px) and (max-height: 760px)  {
    .gridHeightandwidth {
      height: 490px;
    }
  }

  @media screen and (min-height: 720px) and (max-height: 740px)  {
    .gridHeightandwidth {
      height: 470px;
    }
  }

  @media screen and (min-height: 700px) and (max-height: 720px)  {
    .gridHeightandwidth {
      height: 450px;
    }
  }

  @media screen and (min-height: 680px) and (max-height: 700px)  {
    .gridHeightandwidth {
      height: 430px;
    }
  }

  @media screen and (min-height: 660px) and (max-height: 680px)  {
    .gridHeightandwidth {
      height: 410px;
    }
  }

  @media screen and (min-height: 640px) and (max-height: 660px)  {
    .gridHeightandwidth {
      height: 390px;
    }
  }

  @media screen and (min-height: 620px) and (max-height: 640px)  {
    .gridHeightandwidth {
      height: 370px;
    }
  }

  @media screen and (min-height: 600px) and (max-height: 620px)  {
    .gridHeightandwidth {
      height: 350px;
    }
  }

  
 @media screen and (min-height: 580px) and (max-height: 600px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 560px) and (max-height: 580px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 540px) and (max-height: 560px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 520px) and (max-height: 540px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 500px) and (max-height: 520px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 480px) and (max-height: 500px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 460px) and (max-height: 480px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}

@media screen and (min-height: 440px) and (max-height: 460px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}
@media screen and (min-height: 420px) and (max-height: 440px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}
@media screen and (min-height: 400px) and (max-height: 420px)  {
  .gridHeightandwidth {
    height: 350px;
  }
}
@media screen and (min-height: 100px) and (max-height: 400px)  {
  .gridHeightandwidth {
    height: 200px;
  }
}






  