:root {
    --navigationcolor:  rgb(244, 244, 244);
  }

.makeStyles-drawerOpen-25 {
    background-color: white !important;
}

#userside .MuiDrawer-paperAnchorDockedLeft {
      margin-top: 63px !important 
}

.MuiDrawer-paperAnchorDockedLeft {
     border: none !important;
     /* black color issue */
     background-color: var(--navigationcolor) !important; 
     /* background-color: #30369f !important;  */
     /* margin-top: 100px !important;  */
}

.MuiDrawer-docked {
    flex: 0 0 auto;
    /* background-color: #303f9f !important;   */
}

.MuiPaper-elevation4 {
   box-shadow: none !important;
}

.MuiDrawer-paper_Mobile {
  top : -6px !important 
}

.UpperNavItems {
  
  /* -----------------scroller for upper nav items------- */
  /* min-height: 65px;
  max-height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify; */
 
}



.MiddleNavItems { 
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.MiddleNavItemsWithContainerManager { 
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.bottomNavItems{
  bottom: 0px;
  position: fixed;
}

.tNav {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.groupDiv {
  margin-left: 5px;
  margin-right: 5px;
}

.groupIcon {
  color: black;
}

.groupViewsDiv {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.listItemText-dynamicColor {
  letter-spacing: 1px;
  margin-left: 5px;
  font-family: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif";
}

.navDivider {
  letter-spacing: 1px;
  color: gray;
}

.upperNavItemsDiv {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.itemText {
  color: black;
  letter-spacing: 1px;
  font-family: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif";
}

.itemText-dynamicColor {
  letter-spacing: 1px;
  font-family: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif";
}

.dotIcon {
  margin-top: -12px;
}

