:root {
  --drawerwidth: 27%;
}

#preview-drawer .MuiDrawer-paper {
  width: var(--drawerwidth) !important;
  max-width: 50% !important;
  margin-top: 1%;
  height: 95% !important;
}

#preview-drawer .MuiDrawer-root {
  top: -15 !important;
  background-color: rgb(0 0 0 / 4%) !important;
}
#preview-drawer.MuiDrawer-paper {
  top: -15 !important;
  margin-top: -15 !important;
}

#preview-drawer .makeStyles-drawerOpen-25 {
  margin-top: 45px !important;
}

#preview-drawer.MuiDrawer-paperAnchorDockedRight {
  margin-top: 63px !important;
}

#preview-drawer .MuiDrawer-paperAnchorDockedRight {
  margin-top: 56.5px !important;
  /* this is active currently */
}

.MuiDrawer-docked {
  flex: 0 0 auto;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

#preview-drawer-externalTypeView .MuiDrawer-paper {
  width: var(--drawerwidth) !important;
  overflow-x: hidden;
  max-width: 50% !important;
  margin-top: 1%;
  height: 99% !important;
}

#preview-drawer-externalTypeView .MuiDrawer-root {
  top: -15 !important;
  background-color: rgb(0 0 0 / 4%) !important;
}
#preview-drawer-externalTypeView.MuiDrawer-paper {
  top: -15 !important;
  margin-top: -15 !important;
}

#preview-drawer-externalTypeView .makeStyles-drawerOpen-25 {
  margin-top: 45px !important;
}

#preview-drawer-externalTypeView.MuiDrawer-paperAnchorDockedRight {
  margin-top: 63px !important;
}

#preview-drawer-externalTypeView .MuiDrawer-paperAnchorDockedRight {
  margin-top: 0px !important;
  /* this is active currently */
}
