.chip-white{
    display: inline-flex;
    flex-direction: row;
    background-color: #e5e5e5;
    border: none;
    cursor: default;
    height: 24px;
    outline: none;
    padding: 0;
    font-size: 13px;
    /* font-color: #333333; */
    font-family:"Open Sans", sans-serif;
    white-space: nowrap; 
    align-items: center;
    border-radius: 30px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    background-color: #dcdcdc;
}

.chip-content{
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}

.chipWithoutWhiteColor {
    display: inline-block;
    padding: 0px 13px;
    height: 25px;
    font-size: 11px;
    line-height: 25px;
    border-radius: 25px;
}


